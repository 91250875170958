import { IDateRange } from "../@models/date-range.interface";
import { isEqual } from "date-fns";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-struct";
import { NgbTime } from "@ng-bootstrap/ng-bootstrap/timepicker/ngb-time";
import * as momentTimezone from "moment-timezone";

export const toLocalIsoDate = (date: Date): string => {
  const tzoffset = (new Date()).getTimezoneOffset() * 60000;
  return (new Date(date.getTime() - tzoffset)).toISOString().slice(0, -1);
}

export const ngbDateStructToLocalIsoDate = (date: NgbDateStruct): string =>
  toLocalIsoDate( new Date(date.year, date.month - 1, date.day));


export const isTimeRangeInvalid = (startTime: NgbTime, endTime?: NgbTime): boolean => {
  if (startTime.hour < endTime.hour) {
    return false;
  } else if (startTime.hour === endTime.hour) {
    return startTime.minute >= endTime.minute;
  } else {
    return true;
  }
}

export const isDateRangeInvalid = (start: string, end: string): boolean => new Date(end).getTime() <= new Date(start).getTime();

